<script setup>
import AppLoader from '~/components/app/AppLoader.vue'

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'primary'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fullWidth: {
    type: Boolean,
    default: false
  },
  extraClasses: {
    type: String,
    default: ''
  },
  fontStyles: {
    type: String,
    default: 'text-sm font-semibold'
  },
  fontPositioning: {
    type: String,
    default: 'justify-center'
  }
})

const style = computed({
  get: () => {
    if(props.type === 'primary') {
      return 'bg-primary text-white hover:bg-indigo-600 focus-visible:outline-primary disabled:bg-gray-700 disabled:text-white disabled:cursor-not-allowed'
    } else if (props.type === 'secondary') {
      return 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-inset hover:ring-gray-300 focus-visible:outline-primary disabled:bg-gray-700 disabled:text-white disabled:cursor-not-allowed dark:text-white dark:bg-zinc-800 dark:ring-white/10 dark:hover:text-white dark:hover:ring-primary'
    }
  }
})

defineEmits(['click'])
</script>

<template>
  <button type="button" :disabled="disabled" @click="$emit('click')" :class="[style, fullWidth ? 'w-full' : '', extraClasses, fontStyles, fontPositioning]"
  class="flex rounded-lg px-3 py-1.5 leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
    <slot name="icon" />
    {{ text }}
    <template v-if="loading">
      <AppLoader class="ml-2" />
    </template>
  </button>
</template>

<style scoped>

</style>